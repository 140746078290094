<template>
  <div class="withdrawView">
    <div class="title_box">
      <h1 class="title">{{ $t("withdraw.title") }}</h1>
      <el-tabs v-model="activeName" stretch>
        <el-tab-pane label="ERC20" name="ERC20" />
        <el-tab-pane label="TRC20" name="TRC20" />
      </el-tabs>
    </div>

    <div class="info_box">
      <p>
        {{ $t("withdraw.enterWalletAddressAndPrice") }}
        <br />
        {{ $t("withdraw.contactForServiceAfterSend") }}
      </p>
    </div>

    <div class="content">
      <div class="input_box">
        <div>{{ $t("withdraw.coinWalletAddress") }}</div>
        <input
          class="input"
          type="text"
          :placeholder="$t('withdraw.enterWalletAddress')"
          v-model="address"
        />
      </div>

      <div class="input_box">
        <div>{{ $t("withdraw.withdrawPrice") }}</div>
        <input
          class="input"
          type="text"
          :placeholder="$t('withdraw.enterWithdrawPrice')"
          v-model="amount"
        />
      </div>

      <button class="btn" @click="handleClick">
        {{ $t("withdraw.send") }}
      </button>
    </div>
  </div>
</template>

<script>
import { postTradeList } from "@/services/Api.js";

export default {
  inject: ["message"],
  name: "WithdrawView",

  data() {
    return {
      address: "",
      amount: "",
      activeName: "ERC20",
    };
  },

  methods: {
    handleClick() {
      if (this.address == "" || this.amount == "") {
        this.message("withdraw.inputIncorrect", "error");
        return;
      }

      const postdata = {
        address: this.address,
        amount: this.amount,
      };

      postTradeList(postdata)
        .then((res) => {
          console.log(res);
          this.$alert(this.$t("withdraw.contactForService"), {
            confirmButtonText: "OK",
            type: "alert",
            customClass: "alert",
          });

          this.address = "";
          this.amount = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawView {
  height: 100%;

  .title_box {
    @include titleBox;
    .title {
      @include titleBox_title;
    }
  }

  .info_box {
    @include info_box;
  }

  .content {
    width: 75%;
    margin: 0 auto;

    .input_box {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;

      &:first-of-type {
        margin-top: 1rem;
      }

      div {
        width: 100%;
        padding: 12px;
        text-align: left;
      }

      .input {
        margin-bottom: 24px;
        @include formInput;
      }
    }

    .btn {
      @include formBtn;
    }
  }
}
</style>

<style lang="scss">
.el-tabs__item {
  color: #fff !important;
  font-size: 16px !important;
}
.is-active {
  @include font_color("font_color5");
}
</style>
