<template>
  <div class="content">
    <el-form
      :model="ruleForm"
      status-icon
      :rules="userFormRules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm form"
      v-loading="loading"
    >
      <button
        id="useEmail"
        class="title"
        data-type="email"
        @click="(e) => e.preventDefault()"
      >
        {{ $t("register.title") }}
      </button>

      <el-form-item prop="email" :label="$t('register.emailTitle') + ':'">
        <el-input
          :placeholder="$t('register.email')"
          v-model="ruleForm.email"
        />
      </el-form-item>

      <div class="verification">
        <el-form-item
          prop="verify_code"
          :label="$t('register.emailVerify') + ':'"
        >
          <el-input
            type="text"
            :placeholder="$t('register.emailVerify')"
            v-model="ruleForm.verify_code"
          />
        </el-form-item>
        <span @click="handleVerify">{{ $t("register.sendEmailVerify") }}</span>
      </div>

      <el-form-item
        prop="phoneCountry"
        :label="$t('register.phoneAreaCode') + ':'"
      >
        <el-input
          @focus="open = !open"
          type="text"
          :placeholder="$t('register.addPhoneAreaCode')"
          v-model="checkPhoneCountry"
        />
      </el-form-item>

      <VueCountryIntl
        class="country"
        schema="modal"
        v-model="ruleForm.phoneCountry"
        :searchInputPlaceholder="$t('register.addPhoneAreaCode')"
        :visible.sync="open"
        :cancelText="$t('register.cancel')"
      >
      </VueCountryIntl>

      <el-form-item prop="phone_number" :label="$t('register.phone') + ':'">
        <div class="phone_number_content">
          <span class="phoneCountry" v-if="ruleForm.phoneCountry">
            {{ ruleForm.phoneCountry }}
          </span>
          <el-input
            type="text"
            :placeholder="$t('register.warningPhone')"
            v-model="ruleForm.phone_number"
          />
        </div>
      </el-form-item>

      <el-form-item prop="password" :label="$t('register.pass') + ':'">
        <el-input
          type="password"
          :placeholder="$t('register.password')"
          v-model="ruleForm.password"
          autocomplete="off"
        />
      </el-form-item>

      <el-form-item
        prop="checkedPass"
        :label="$t('register.checkPassword') + ':'"
      >
        <el-input
          type="password"
          :placeholder="$t('register.checkPassword')"
          autocomplete="off"
          v-model="ruleForm.checkedPass"
        />
      </el-form-item>

      <!-- <el-form-item prop="code" :label="$t('register.invitationCode') + ':'">
        <el-input
          type="text"
          :placeholder="$t('register.invitationCode')"
          v-model="ruleForm.code"
        />
      </el-form-item> -->

      <label for="checkbox">
        <input class="checkbox" type="checkbox" v-model="checked" />
        <span class="str" @click="handleCheckBox">{{
          $t("register.checked")
        }}</span>
      </label>

      <div class="row">
        <el-form-item prop="_rucaptcha">
          <el-input
            type="text"
            :placeholder="$t('forgotpassword.enterCaptcha')"
            v-model="ruleForm._rucaptcha"
          />
        </el-form-item>

        <div class="verify" @click="refreshCode">
          <SIdentify :identifyCode="identifyCode"></SIdentify>
        </div>
      </div>

      <el-button class="btn" @click="submitForm">
        {{ $t("register.register") }}
      </el-button>

      <div class="footer">
        <router-link to="/login" class="link">
          {{ $t("register.login") }}
        </router-link>
      </div>
    </el-form>
  </div>
</template>

<script>
import SIdentify from "@/components/Sldentify/Sldentify.vue";
import { postMembers, postVerify } from "@/services/Api.js";

export default {
  inject: ["reload", "message"],
  name: "RegisterView",
  components: { SIdentify },

  data() {
    return {
      checked: false,
      validation: Date.now(), //刷新驗證碼圖片
      open: false, //開關手機區碼組件
      loading: false,
      identifyCode: "1234",
      identifyCodes: "0123456789abcdwerwshdjeJKDHRJHKOOPLMKQ",

      ruleForm: {
        email: "",
        verify_code: "", //收信驗證碼
        phoneCountry: "", //手機區碼
        password: "",
        checkedPass: "", //確認密碼
        code: "2K6YM", //邀請碼
        phone_number: "",
        _rucaptcha: "", //驗證碼
      },
    };
  },

  computed: {
    checkPhoneCountry() {
      return this.ruleForm.phoneCountry
        ? this.$t("register.areaCode") + this.ruleForm.phoneCountry
        : this.$t("register.addPhoneAreaCode");
    },

    /** 驗證表單 */
    userFormRules() {
      const rules = {
        email: [
          {
            required: true,
            message: this.$t("register.warningEmail"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("register.checkEmail"),
            trigger: "blur",
          },
        ],
        verify_code: [
          {
            required: true,
            message: this.$t("register.warninEmailVerify"),
            trigger: "blur",
          },
        ],
        phoneCountry: [
          {
            required: true,
            message: this.$t("register.addPhoneAreaCode"),
            trigger: ["blur", "change"],
          },
        ],
        phone_number: [
          {
            required: true,
            message: this.$t("register.warningPhone"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("register.warninPassword"),
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: this.$t("register.warningInvitationCode"),
            trigger: "blur",
          },
        ],
        checkedPass: [
          {
            required: true,
            message: this.$t("register.againCheckPassword"),
            trigger: "blur",
          },
        ],
        _rucaptcha: [
          {
            required: true,
            message: this.$t("forgotpassword.enterCaptcha"),
            trigger: "blur",
          },
        ],
      };
      return rules;
    },
  },

  mounted() {
    this.refreshCode();
  },

  methods: {
    register() {
      if (this.checked) {
        if (this.ruleForm.password === this.ruleForm.checkedPass) {
          if (this.ruleForm._rucaptcha === this.identifyCode) {
            this.loading = true;

            let data = JSON.parse(JSON.stringify(this.ruleForm));

            data.phone_number =
              this.ruleForm.phoneCountry.toString() +
              this.ruleForm.phone_number.toString();

            let locale = window.localStorage.getItem("locale");
            locale =
              locale == "zht" ? "zh-TW" : locale == "zhs" ? "zh-CN" : "en";

            data.locale = locale;

            postMembers(data)
              .then((res) => {
                const { data } = res;
                if (data.success) {
                  this.message("register.success", "success");
                  this.$router.push("/").catch(() => {});
                  this.reload();
                } else {
                  this.message(
                    (data.errors[0].field ? data.errors[0].field + " " : "") +
                      data.errors[0].message,
                    "warning"
                  );
                }
                this.loading = false;
              })
              .catch(() => (this.loading = false));
          } else {
            this.message("forgotpassword.captchaDoesNotMatch", "error");
          }
        } else {
          this.message("register.warningPassword", "error");
        }
      } else {
        this.message("register.warning", "error");
      }
    },

    /** 更新驗證碼圖片 */
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },

    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },

    // 隨機生成驗證碼字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)];
      }
    },

    /** 發送驗證碼 */
    handleVerify() {
      if (this.ruleForm.email) {
        let locale = window.localStorage.getItem("locale");
        locale = locale == "zht" ? "zh-TW" : locale == "zhs" ? "zh-CN" : "en";

        postVerify({
          email: this.ruleForm.email,
          locale: locale,
        })
          .then((res) => {
            const { data } = res;
            if (data.success) {
              this.message("register.sendVerify", "success");
            } else {
              this.message(data.errors[0].message, "error");
            }
          })
          .catch((err) => console.log(err));
      } else {
        this.message("register.warningEmail", "warning");
      }
    },

    handleCheckBox() {
      this.checked = !this.checked;
    },

    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.register();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.country {
  padding-top: 64px;
}

.content {
  @include formContent;

  ::v-deep .el-loading-mask {
    @include background_color("background_color11");
  }

  .form {
    @include form;

    .title {
      @include formPageTitle;
    }

    ::v-deep .el-form-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;

      .el-form-item__label {
        text-align: left;
        font-weight: bold;
      }

      .el-form-item__content {
        margin-left: 0 !important;
        display: flex;
      }
    }

    .phone_number_content {
      display: flex;
      width: 100%;

      .phoneCountry {
        @include font_color("font_color4");
        border: 1px solid;
        border-right: none;
        @include border_color("border_color5");
        height: 40px;
        padding: 0 11px;
        font-size: 14px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      ::v-deep .el-input__inner {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .input {
      ::v-deep .el-input__inner {
        @include formInput;
      }
    }

    .verification {
      position: relative;
      display: flex;
      align-items: center;

      ::v-deep .el-input__inner {
        margin-bottom: 0;
      }

      span {
        position: absolute;
        font-size: 12px;
        right: 30px;
        @include font_color("font_color5");
        cursor: pointer;
        top: calc(50% + 4px);
      }
    }
    .str {
      @include font_color("font_color4");
    }

    label {
      @include font_color("font_color8");
      display: flex;
      font-size: 14px;
      text-align: left;

      span {
        padding-left: 4px;
        line-height: 1.2;
      }

      .checkbox {
        min-width: 13px;
      }
    }

    .btn {
      @include formBtn;
    }

    .row {
      display: flex;
      margin-top: 24px;

      .verify {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      a {
        @include font_color("font_color5");
        text-decoration: none;
      }
    }
  }
}
</style>
