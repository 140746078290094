<template>
  <div class="charge">
    <div class="info_box" v-loading="loading">
      <p class="select_box" v-if="this.$route.params.walletName == 'USDT'">
        {{ $t("charge.usdtType") }}:
        <select class="select" v-model="selectCode">
          <option label="USDT@ERC20" value="USDT@ERC20">USDT@ERC20</option>
          <option label="USDT@TRC20" value="USDT@TRC20">USDT@TRC20</option>
        </select>
      </p>

      <p></p>
      <p>
        {{ $t("charge.chargeIntoThisWallet1") }}
        {{ this.$route.params.walletName }}
        {{ $t("charge.chargeIntoThisWallet2") }}
        <br />
        {{ $t("charge.contactUsToHandle") }}
      </p>

      <div class="wallet">
        <div>{{ $t("charge.coinWalletAddress") }}:</div>
        <div class="coinAddress">{{ coinWallet }}</div>

        <div class="copy_box">
          <span
            class="copy"
            :class="[{ gray: coinWallet == '' }]"
            @click="coinWallet ? copy(coinWallet) : null"
          >
            {{ $t("charge.copyCoinWalletAddress") }}
          </span>
          <span
            class="copy"
            :class="[{ gray: coinWallet == '' }]"
            @click="coinWallet ? makeQRcodeFun(coinWallet) : null"
          >
            {{ $t("charge.showQRcode") }}
          </span>
        </div>

        <!-- <div class="img_box" v-if="QRcodeUrl">
          <img :src="QRcodeUrl" alt="qrcode" />
          <div class="logo"></div>
        </div> -->
      </div>
    </div>

    <el-divider></el-divider>

    <div class="title">{{ $t("charge.chargeRecord") }}</div>

    <div class="content">
      <div class="no_data" v-if="chargeList.length == 0">
        {{ $t("charge.noData") }}
      </div>
      <div class="list" v-for="(item, index) in chargeList" :key="index">
        <div class="row">
          <span>{{ $t("charge.id") }}</span>
          <span>{{ item.id }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.amount") }}</span>
          <span>{{ item.amount }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.fee") }}</span>
          <span>{{ item.fee }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.created_at") }}</span>
          <span>{{ handleTime(item.created_at) }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.updated_at") }}</span>
          <span>{{ handleTime(item.updated_at) }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.state") }}</span>
          <span>{{ stateFun(item.state) }}</span>
        </div>
        <div class="row">
          <span>{{ $t("charge.charge_fund_uid") }}</span>
          <span class="break_all">{{ item.fund_uid }}</span>
        </div>
      </div>

      <el-pagination
        layout="prev, pager, next"
        :page-count="RecordData.total_pages"
        @current-change="changePage"
        :pager-count="5"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { gettVerify, getRecharge, getRechargeList } from "@/services/Api.js";
import moment from "moment";

export default {
  inject: ["message"],
  name: "ChargeComponent",

  watch: {
    selectCode() {
      this.gettVerifyFun();
    },
  },

  data() {
    return {
      loading: false,
      coinWallet: "",
      QRcodeUrl: null,
      selectCode: "USDT@ERC20",
      chargeList: [],
      RecordData: {},
      /* global glob */
      // domainType: "vcex", // mtf/vcex
      domainType: glob.domainType, // mtf/vcex
    };
  },

  mounted() {
    this.gettVerifyFun();

    this.getRechargeFun();
    // this.getRechargeListFun();
  },

  methods: {
    copy(val) {
      this.$copyText(val)
        .then(() => this.message("charge.copySuccess", "success"))
        .catch(() => this.message("charge.copyFail", "error"));
    },

    gettVerifyFun() {
      this.loading = true;
      this.coinWallet = "";

      const currency =
        this.$route.params.walletName == "USDT"
          ? this.selectCode
          : this.$route.params.walletName;

      gettVerify(currency)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.coinWallet = data.data.deposit_address;
            // this.makeQRcodeFun(data.data.deposit_address);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          // this.message("charge.gettVerifyFail", "error");
          this.$alert(
            this.$t("charge.gettVerifyFail"),
            this.$t("charge.gettVerifyFailTitle"),
            {
              confirmButtonText: "OK",
              customClass: "alert",
              dangerouslyUseHTMLString: true,
              // callback: () => {
              // this.$router.push("/").catch(() => {});
              // this.reload();
              // },
            }
          );
        });
    },

    getRechargeFun() {
      getRecharge(this.$route.params.walletName.toLowerCase(), this.currentPage)
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.chargeList = res.data.data.deposits;
            this.RecordData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getRechargeListFun() {
      getRechargeList()
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    makeQRcodeFun(text) {
      QRCode.toDataURL(text)
        .then((url) => {
          // this.QRcodeUrl = url;
          console.log(url);
          const html =
            this.domainType == "mtf"
              ? `
            <div class="img_box">
              <img src="${url}" alt="qrcode" />
              <div class="logo logo_1"></div>
            </div>
          `
              : `
            <div class="img_box">
              <img src="${url}" alt="qrcode" />
              <div class="logo logo_2"></div>
            </div>
          `;
          this.$alert(html, this.$t("charge.showQRcode"), {
            confirmButtonText: "OK",
            customClass: "alert",
            dangerouslyUseHTMLString: true,
          });
        })
        .catch((err) => console.error(err));
    },

    handleTime(time) {
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },

    stateFun(state) {
      switch (state) {
        case "submitting":
          return this.$t("charge.submitting");

        case "cancelled":
          return this.$t("charge.cancelled");

        case "submitted":
          return this.$t("charge.submitted");

        case "rejected":
          return this.$t("charge.rejected");

        case "accepted":
          return this.$t("charge.accepted");

        case "checked":
          return this.$t("charge.checked");

        case "warning":
          return this.$t("charge.warning");

        default:
          return "";
      }
    },

    changePage(value) {
      console.log(value);
      this.currentPage = value;
      this.getRechargeFun();
    },
  },
};
</script>

<style lang="scss" scoped>
.charge {
  height: 100%;
  position: relative;

  .title_box {
    @include titleBox;

    .title {
      @include titleBox_title;
    }
  }

  .info_box {
    @include info_box;
    .select_box {
      margin-bottom: 20px;
      .select {
        @include font_color("background_color2");
        font-weight: bold;
        @include background_color("background_color3");
        @include select_background_image("background_color5");
        border: unset;
        font-size: 18px;
        padding: 5px;
        border-radius: 5px;
      }
      select {
        outline: none;
      }
    }
  }

  .wallet {
    display: flex;
    flex-direction: column;
    // padding-bottom: 2rem;
    .coinAddress {
      word-break: break-all;
      padding: 0 1rem;
      @include font_color("font_color5");
    }

    .copy_box {
      @include flex-set();
      // justify-content: space-evenly;
      margin: 10px 0;
    }
    div {
      margin-bottom: 0.5rem;

      span {
        &.copy {
          border: 1px solid;
          display: inline-flex;
          padding: 0.5rem;
          border-radius: 5px;
          cursor: pointer;
          @include font_color("font_color3");
          @include border_color("border_color3");
          margin: 5px;

          &:hover {
            transform: scale(1.1);
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;
          }
        }
      }
      .gray {
        opacity: 0.5;
        transform: scale(1) !important;
        cursor: not-allowed !important;
      }
    }
  }
}

.content {
  @include record_wrap;
  .no_data {
    padding: 40px;
    @include font_color("font_color8");
  }
  .list {
    @include record_box;
    padding: 1rem 1.25rem;

    &:first-of-type {
      margin-top: 1rem;
    }

    h1 {
      margin-bottom: 1rem;
    }

    .row {
      @include record;
      margin: 4px 0;
      .break_all {
        max-width: 70%;
        word-break: break-all;
        text-align: right;
      }
    }
  }
  ::v-deep .el-loading-mask {
    @include background_color("background_color10");
  }
  ::v-deep .el-pagination {
    .btn-prev {
      @include font_color("font_color3");
    }
    .btn-next {
      @include font_color("font_color3");
    }
  }
  ::v-deep .el-loading-mask {
    @include background_color("background_color10");
  }
}
</style>

<style lang="scss">
.el-tabs__item {
  color: #fff !important;
  font-size: 16px !important;
}
.is-active {
  @include font_color("font_color5");
}
.img_box {
  position: relative;
  margin: 0 auto;
  @include flex-set();
  width: 250px;
  height: 250px;
  img {
    width: 250px;
    max-width: 100%;
  }
  .logo {
    background-position: center;
    background-size: contain;
    position: absolute;
    border: 3px solid;
    border-radius: 50%;
    width: 20%;
    height: 20%;
    top: calc(50% - 10%);
    left: calc(50% - 10%);
    @include border_color("border_color3");
  }
  .logo_1 {
    background-image: url("~@/assets/images/mtf/small_logo.png");
  }
  .logo_2 {
    background-image: url("~@/assets/images/vcex/small_logo.png");
  }
  .logo_3 {
    background-image: url("~@/assets/images/wdc/small_logo.svg");
  }
}
</style>
